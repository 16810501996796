import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _522ba430 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _839c16f6 = () => interopDefault(import('../pages/auth/forgotten.vue' /* webpackChunkName: "pages/auth/forgotten" */))
const _82a5c058 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _c4702a8c = () => interopDefault(import('../pages/auth/reset.vue' /* webpackChunkName: "pages/auth/reset" */))
const _8f7e42e6 = () => interopDefault(import('../pages/auth/welcome.vue' /* webpackChunkName: "pages/auth/welcome" */))
const _5cd0f7e1 = () => interopDefault(import('../modules/users/pages/index.vue' /* webpackChunkName: "" */))
const _65024efd = () => interopDefault(import('../modules/users/pages/create.vue' /* webpackChunkName: "" */))
const _326c2b4b = () => interopDefault(import('../modules/users/pages/edit.vue' /* webpackChunkName: "" */))
const _2671e299 = () => interopDefault(import('../modules/stores/pages/index.vue' /* webpackChunkName: "" */))
const _20270993 = () => interopDefault(import('../modules/stores/pages/edit.vue' /* webpackChunkName: "" */))
const _39a810d8 = () => interopDefault(import('../modules/landingPages/pages/index.vue' /* webpackChunkName: "" */))
const _abf174ac = () => interopDefault(import('../modules/landingPages/pages/create.vue' /* webpackChunkName: "" */))
const _55e45b74 = () => interopDefault(import('../modules/landingPages/pages/create/base.vue' /* webpackChunkName: "" */))
const _54abe400 = () => interopDefault(import('../modules/landingPages/pages/create/final-order-confirmation.vue' /* webpackChunkName: "" */))
const _affb2918 = () => interopDefault(import('../modules/landingPages/pages/create/cross-sell.vue' /* webpackChunkName: "" */))
const _3c146512 = () => interopDefault(import('../modules/landingPages/pages/create/sepa-enhancement.vue' /* webpackChunkName: "" */))
const _0377d2a2 = () => interopDefault(import('../modules/landingPages/pages/create/upsell.vue' /* webpackChunkName: "" */))
const _e9f75854 = () => interopDefault(import('../modules/landingPages/pages/create/video.vue' /* webpackChunkName: "" */))
const _03fdca44 = () => interopDefault(import('../modules/landingPages/pages/create/email-signup.vue' /* webpackChunkName: "" */))
const _938def9e = () => interopDefault(import('../modules/landingPages/pages/create/webinar-registration.vue' /* webpackChunkName: "" */))
const _405375b4 = () => interopDefault(import('../modules/landingPages/pages/create/free-signup.vue' /* webpackChunkName: "" */))
const _8674713a = () => interopDefault(import('../modules/landingPages/pages/create/multi-offer.vue' /* webpackChunkName: "" */))
const _b8f12374 = () => interopDefault(import('../modules/landingPages/pages/create/appointment-booking.vue' /* webpackChunkName: "" */))
const _4df23a15 = () => interopDefault(import('../modules/credentials/pages/index.vue' /* webpackChunkName: "" */))
const _0b3792a3 = () => interopDefault(import('../modules/credentials/pages/new.vue' /* webpackChunkName: "" */))
const _6bbfd297 = () => interopDefault(import('../modules/credentials/pages/edit.vue' /* webpackChunkName: "" */))
const _51d71baa = () => interopDefault(import('../modules/fileManager/pages/index.vue' /* webpackChunkName: "" */))
const _3e9b96a2 = () => interopDefault(import('../modules/tenants/pages/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _522ba430,
    name: "index___en"
  }, {
    path: "/auth/forgotten",
    component: _839c16f6,
    name: "auth-forgotten___de"
  }, {
    path: "/auth/login",
    component: _82a5c058,
    name: "auth-login___de"
  }, {
    path: "/auth/reset",
    component: _c4702a8c,
    name: "auth-reset___de"
  }, {
    path: "/auth/welcome",
    component: _8f7e42e6,
    name: "auth-welcome___de"
  }, {
    path: "/en/auth/forgotten",
    component: _839c16f6,
    name: "auth-forgotten___en"
  }, {
    path: "/en/auth/login",
    component: _82a5c058,
    name: "auth-login___en"
  }, {
    path: "/en/auth/reset",
    component: _c4702a8c,
    name: "auth-reset___en"
  }, {
    path: "/en/auth/welcome",
    component: _8f7e42e6,
    name: "auth-welcome___en"
  }, {
    path: "/",
    component: _522ba430,
    name: "index___de"
  }, {
    path: "/users",
    component: _5cd0f7e1,
    name: "users___de"
  }, {
    path: "/en/users",
    component: _5cd0f7e1,
    name: "users___en"
  }, {
    path: "/users/create",
    component: _65024efd,
    name: "users-create___de"
  }, {
    path: "/en/users/create",
    component: _65024efd,
    name: "users-create___en"
  }, {
    path: "/users/edit",
    component: _326c2b4b,
    name: "users-edit___de"
  }, {
    path: "/en/users/edit",
    component: _326c2b4b,
    name: "users-edit___en"
  }, {
    path: "/stores",
    component: _2671e299,
    name: "stores___de"
  }, {
    path: "/en/stores",
    component: _2671e299,
    name: "stores___en"
  }, {
    path: "/stores/edit",
    component: _20270993,
    name: "stores-edit___de"
  }, {
    path: "/en/stores/edit",
    component: _20270993,
    name: "stores-edit___en"
  }, {
    path: "/landingpages",
    component: _39a810d8,
    name: "landingpages___de"
  }, {
    path: "/en/landingpages",
    component: _39a810d8,
    name: "landingpages___en"
  }, {
    path: "/landingpages/create",
    component: _abf174ac,
    name: "landingpages-create___de"
  }, {
    path: "/en/landingpages/create",
    component: _abf174ac,
    name: "landingpages-create___en"
  }, {
    path: "/landingpages/create/base",
    component: _55e45b74,
    name: "landingpages-create-base___de"
  }, {
    path: "/en/landingpages/create/base",
    component: _55e45b74,
    name: "landingpages-create-base___en"
  }, {
    path: "/landingpages/create/final-order-confirmation",
    component: _54abe400,
    name: "landingpages-create-final-order-confirmation___de"
  }, {
    path: "/en/landingpages/create/final-order-confirmation",
    component: _54abe400,
    name: "landingpages-create-final-order-confirmation___en"
  }, {
    path: "/landingpages/create/cross-sell",
    component: _affb2918,
    name: "landingpages-create-cross-sell___de"
  }, {
    path: "/en/landingpages/create/cross-sell",
    component: _affb2918,
    name: "landingpages-create-cross-sell___en"
  }, {
    path: "/landingpages/create/sepa-enhancement",
    component: _3c146512,
    name: "landingpages-create-sepa-enhancement___de"
  }, {
    path: "/en/landingpages/create/sepa-enhancement",
    component: _3c146512,
    name: "landingpages-create-sepa-enhancement___en"
  }, {
    path: "/landingpages/create/upsell",
    component: _0377d2a2,
    name: "landingpages-create-upsell___de"
  }, {
    path: "/en/landingpages/create/upsell",
    component: _0377d2a2,
    name: "landingpages-create-upsell___en"
  }, {
    path: "/landingpages/create/video",
    component: _e9f75854,
    name: "landingpages-create-video___de"
  }, {
    path: "/en/landingpages/create/video",
    component: _e9f75854,
    name: "landingpages-create-video___en"
  }, {
    path: "/landingpages/create/email-signup",
    component: _03fdca44,
    name: "landingpages-create-email-signup___de"
  }, {
    path: "/en/landingpages/create/email-signup",
    component: _03fdca44,
    name: "landingpages-create-email-signup___en"
  }, {
    path: "/landingpages/create/webinar-registration",
    component: _938def9e,
    name: "landingpages-create-webinar-registration___de"
  }, {
    path: "/en/landingpages/create/webinar-registration",
    component: _938def9e,
    name: "landingpages-create-webinar-registration___en"
  }, {
    path: "/landingpages/create/free-signup",
    component: _405375b4,
    name: "landingpages-create-free-signup___de"
  }, {
    path: "/en/landingpages/create/free-signup",
    component: _405375b4,
    name: "landingpages-create-free-signup___en"
  }, {
    path: "/landingpages/create/multi-offer",
    component: _8674713a,
    name: "landingpages-create-multi-offer___de"
  }, {
    path: "/en/landingpages/create/multi-offer",
    component: _8674713a,
    name: "landingpages-create-multi-offer___en"
  }, {
    path: "/landingpages/create/appointment-booking",
    component: _b8f12374,
    name: "landingpages-create-appointment-booking___de"
  }, {
    path: "/en/landingpages/create/appointment-booking",
    component: _b8f12374,
    name: "landingpages-create-appointment-booking___en"
  }, {
    path: "/credentials",
    component: _4df23a15,
    name: "credentials___de"
  }, {
    path: "/en/credentials",
    component: _4df23a15,
    name: "credentials___en"
  }, {
    path: "/credentials/new",
    component: _0b3792a3,
    name: "credentials-new___de"
  }, {
    path: "/en/credentials/new",
    component: _0b3792a3,
    name: "credentials-new___en"
  }, {
    path: "/credentials/edit",
    component: _6bbfd297,
    name: "credentials-edit___de"
  }, {
    path: "/en/credentials/edit",
    component: _6bbfd297,
    name: "credentials-edit___en"
  }, {
    path: "/filemanager",
    component: _51d71baa,
    name: "filemanager___de"
  }, {
    path: "/en/filemanager",
    component: _51d71baa,
    name: "filemanager___en"
  }, {
    path: "/tenants",
    component: _3e9b96a2,
    name: "tenants___de"
  }, {
    path: "/en/tenants",
    component: _3e9b96a2,
    name: "tenants___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
