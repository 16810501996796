import { render, staticRenderFns } from "./User.vue?vue&type=template&id=16dcf6a1&scoped=true"
import script from "./User.vue?vue&type=script&lang=js"
export * from "./User.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16dcf6a1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconList: require('/var/www/web134/htdocs/lpm.pl-x.de/deployer/releases/20240905081558/frontend/components/IconList.vue').default})
